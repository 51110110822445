<template>
    <div class="bg-light">
        <div class="container pt-4 pb-4 bg-white">
            <div class="profile-bg">
                <div class="d-flex align-items-center top-bg p-3">
                    <span>2-Factor Authentication</span>
                    <vs-switch v-model="authState" class=" ml-2 mr-2"/>
                    <span :class="authState ? '' : 'text-muted'">{{authState ? 'Enabled' : 'Disabled'}}</span>
                </div>
                <div class="bg-white p-3">
                    <p class="lead">
                        Add an extra layer of security to your account by using a one-time authentication code together with your password at the time of login.
                    </p>
                </div>
            </div>
            <br>
            <div class="profile-bg mt-4">
                <div class="d-flex align-items-center top-bg p-3">
                    <span>Merchant ID:</span>
                    <h6 class="ml-2">UTUP039_SEKKW</h6>
                </div>
                <div class="bg-white p-3">
                    <span>Contact Name<span class="float-right">{{user.user_name}}</span></span>
                    <vs-divider></vs-divider>
                    <span>Contact Email<span class="float-right">{{user.user_email}}</span></span>
                    <vs-divider></vs-divider>
                    <span>Contact Phone Number<span class="float-right">{{user.user_phone_number || '---'}}</span></span>
                    <vs-divider></vs-divider>
                    <span>Business Name<span class="float-right">{{user.business_name || '---'}}</span></span>
                    <vs-divider></vs-divider>
                    <span>Business Type<span class="float-right">{{user.business_type || '---'}}</span></span>
                    <vs-divider></vs-divider>
                    <span>Business Category<span class="float-right">{{user.business_category || '---'}}</span></span>
                    <vs-divider></vs-divider>
                    <span>Registration Date<span class="float-right">{{creationDate}}</span></span>
                    <vs-divider></vs-divider>
                    <span>Account Status<span class="float-right text-success" v-if="user.account_activated">'Account Activated'</span><span @click="activate" class="float-right text-primary cursor-pointer" v-if="!user.account_activated">Activate account</span></span>
                    <vs-divider></vs-divider>
                    <span>Limit per Single Transaction<span class="float-right">{{limit}}</span></span>
                </div>
            </div>
            <br>
            <div class="profile-bg mt-4">
                <div class="d-flex align-items-center top-bg p-3">
                    <h6 class="text-muted">Support Details</h6>
                </div>
                <div class="bg-white p-3">
                    <span>Support Phone No.<span class="float-right">{{user.support_phone_number || '---'}}</span></span>
                    <vs-divider></vs-divider>
                    <span>Support Email<span class="float-right">{{user.support_email || '---'}}</span></span>
                    <vs-divider></vs-divider>
                    <span>Website<span class="float-right">{{user.website_url || '---'}}</span></span>
                </div>
            </div>
            <br>
            <div class="profile-bg mt-4">
                <div class="d-flex align-items-center top-bg p-3">
                    <h6 class="text-muted">Authorization Details</h6>
                </div>
                <div class="bg-white p-3">
                    <p>
                        Add an extra layer of security to your account by using a one-time authentication code together with your password at the time of login.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';

export default {
    name: 'Profile',
    data() {
        return {
            authState: false,
            limit: '₦5,000,000'
        }
    },
    computed: {
        ...mapState(['user']),
        creationDate() {
            return this.user.creation_date ? moment(this.user.creation_date).format('MMM Do YYYY, h:mm a') : '---'
        }
    },
    methods: {
        activate() {
            this.$router.push('/app/activation')
        }
    }
}
</script>

<style lang="scss" scoped>
    .profile-bg {
        border: 1px solid #eff2f7;
    }

    .top-bg {
        background-color: #f2f2f2;
    }
</style>