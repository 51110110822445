<template>
<div>
    <h3>My Account</h3>
    <vs-card class="mt-4 p-0 account">
        <div>
            <vs-tabs class="account p-0">
                <vs-tab label="Profile" class="pl-0 pr-0 pb-0">
                    <Profile/>
                </vs-tab>
                <vs-tab label="Credits" class="pl-0 pr-0 pb-0">
                    <Credits/>
                </vs-tab>
                <vs-tab label="Manage Team" class="pl-0 pr-0 pb-0">
                    <ManageTeams/>
                </vs-tab>
            </vs-tabs>
        </div>
    </vs-card>
</div>
</template>

<script>
import Profile from './Profile'
import Credits from './Credits'
import ManageTeams from './ManageTeams'

export default {
    name: 'Account',
    components: { Profile, Credits, ManageTeams }
}
</script>

<style lang="scss" scoped>
    
</style>