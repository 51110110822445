<template>
    <div class="p-4 text-center bg-light">
        <h3 class="text-muted">You currently have no credit</h3>
    </div>
</template>

<script>
export default {
    name: 'Credits'
}
</script>